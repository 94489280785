<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine, invalid, handleSubmit }"
  >
    <b-card border-variant="none">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <participant-form
          :user.sync="user"
          :need-to-clear-form="needToClearForm"
          @cleared="onCleared"
        />
        <b-row class="mt-5">
          <b-col
            md="3"
          >
            <b-button
              block
              type="reset"
              variant="outline-dark"
              @click="close"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button
              block
              type="submit"
              variant="primary"
            >
              <b-spinner
                v-if="isUpdating" 
                small
              />
              <span
                v-if="isUpdating"
              > Updating...</span>
              <span v-else>Submit</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BButton,
  BSpinner
} from "bootstrap-vue";
import { User } from '@models/user';
import usersService  from "@/services/usersService";
import ParticipantForm from "./ParticipantForm.vue";
import { ValidationObserver } from "vee-validate";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { UserProgramSettings, userStatus, userCreationErrorCodes } from '@models';
import _cloneDeep from "lodash/cloneDeep";

import {
  getValidationState
} from "@/libs/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,  
    ValidationObserver,
    BSpinner,
    ParticipantForm
  },
  data() {
    return {
      user: new User(),
      isUpdating: false,
      needToClearForm: false,
    };
  },
  computed: {
    dir(){
      return 'ltr';
    }
  },
  methods: {
    close() {
      this.clearForm();
      this.needToClearForm = true;
      this.$emit('close');
    },
    clearForm() {
      this.user = new User({
        user_roles: [],
        user_program_settings: [{
          ...new UserProgramSettings({program_id: this.$route.params.id, email_notifications: true}),
        }],
        status_id: userStatus.ACTIVE
      });
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async onSubmit(isForced = false) {
      try {
        this.isUpdating = true;
        const programId = this.$route.params.id;
        let payload = _cloneDeep(this.user);
        if (isForced) {
          payload.force = true;
        }
        const response = await usersService.createParticipant(programId, payload);
        this.$emit("userAdded", response);
        this.$toast(makeSuccessToast('User created successfully.'));
        this.clearForm();
        this.needToClearForm = true;
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        }
        if (status === 409 && data.code === userCreationErrorCodes.EXISTING_USER_ALLOWED) {
          this.$bvModal
            .msgBoxConfirm(data.message, {
              title: 'Add Participant',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Add',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(async value => {
              if(value) {
                this.onSubmit(true);
              }
            });
        } else {
          this.$toast(makeErrorToast(data.message));
          this.$log.error(e);
        }
      }
      finally {
        this.isUpdating = false;
      }
    },
    onCleared() {
      this.needToClearForm = false;
    }
  },
  setup() {
    return {
      getValidationState
    };
  }
};
</script>
<style lang="scss" scoped>
.card {
    box-shadow: none;
}
</style>
