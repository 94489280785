<template>
  <b-modal
    id="form-group"
    :visible="show"
    title="Create or Add/Remove Users to Custom Groups"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    centered
    ok-only
    ok-title="Close"
    @hidden="onHidden"
  >
    <b-card>
      <b-form>
        <b-row>
          <b-col sm="12">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Please Select From An Action Below"
            >
              <b-form-radio-group
                v-model="selectedAction"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radio-inline"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="selectedAction">
          <b-col
            v-if="selectedAction === 'new'"
            sm="6"
            offset="3"
          >
            <b-form-group 
              label="Create a New Custom Group" 
              label-for="custom-group-name"
            >
              <b-form-input
                id="custom-group-name"
                v-model="customGroupName"
                placeholder="Enter the name ..."
              />
            </b-form-group>
            <b-button
              variant="primary"
              @click="createNewGroup"
            >
              <b-spinner
                v-if="isUpdating"
                small
              />
              Create a New Group
            </b-button>
          </b-col>
          <b-col
            v-if="selectedAction === 'add'"
            sm="6"
            offset="3"
          >
            <h6 class="text-center mb-1 mt-1">
              <b>Participants cannot be manually added to filtered groups.</b>
            </h6>
            <b-form-group 
              label="Select Groups To Add Users." 
              label-for="Select Custom Group"
            >
              <v-select
                v-model="selectedAddGroups"
                placeholder="Please select a group"
                :options="usersAddToGroups"
                :reduce="(option) => option.id"
                label="name"
                multiple
                :loading="isLoading"
                transition="fade"
              />
            </b-form-group>
            <b-button
              variant="primary"
              @click="addUsersToGroups"
            >
              <b-spinner
                v-if="isUpdating"
                small
              />
              Add Users to Groups
            </b-button>
          </b-col>
          <b-col
            v-if="selectedAction === 'remove'"
            sm="6"
            offset="3"
          >
            <h6 class="text-center mb-1 mt-1">
              <b>Participants cannot be manually removed from filtered groups.</b>
            </h6>
            <b-form-group 
              label="Select Groups To Remove Users." 
              label-for="Select Custom Group"
            >
              <v-select
                v-model="selectedRemoveGroups"
                placeholder="Please select a group"
                :options="usersRemoveGroups"
                :reduce="(option) => option.id"
                label="name"
                multiple
                :loading="isLoading"
                transition="fade"
              />
            </b-form-group>
            <b-button
              variant="primary"
              @click="removeUsersToGroups"
            >
              <b-spinner
                v-if="isUpdating"
                small
              />
              Remove Users from Groups
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-modal>
</template>
<script>
      
import {  
  BModal,
  BRow,
  BCol,
  BButton,
  BForm,
  BCard,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";     
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { groupsService } from "@/services";
import { groupType } from "@/models";      
export default {
  name: 'FormNewGroupModal',
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BForm,
    BCard,
    vSelect,
    BFormInput,
    BFormGroup,
    BFormRadioGroup, 
    BSpinner
  },
  props:{
    users: {
      type: [Array,Object],
      default: () => [],
      required: true
    },
    show: {
      type: Boolean,
    },
    usersGroups: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      shallShowFormGroupModal: false,
      selectedAddGroups: [],
      selectedRemoveGroups: [],
      customGroupsList: [],
      isLoading: false,
      customGroupName: '',
      isUpdating: false,
      selectedAction: undefined,
      options: [
        { text: 'Create a New Group', value: 'new' },
        { text: 'Select Groups To Add Users to.', value: 'add' },
        { text: 'Select Groups To Remove Users from', value: 'remove' }
      ]
    };
  },
  computed: {
    userGroupIds () {
      return this.usersGroups.map(u => u.id);
    },
    usersAddToGroups () {
      if(this.usersGroups.length === 0) {
        return this.customGroupsList;
      }
      return this.customGroupsList.filter(g => {
        if(!this.userGroupIds.includes(g.id)) {
          return g;
        }
      });
    },
    usersRemoveGroups () {
      if(this.usersGroups.length === 0) {
        return this.customGroupsList;
      }
      return this.customGroupsList.filter(g => {
        if(this.userGroupIds.includes(g.id)) {
          return g;
        }
      });
    }
  },
  watch: {
    show(n) {
      if(n) {
        this.fetchCustomGroupList();
      }
    }
  },
  created() {
    this.fetchCustomGroupList();
  }, 
  methods: {
    onHidden() {
      this.customGroupName = '';
      this.shallShowFormGroupModal = false,  
      this.selectedAddGroups = [];
      this.selectedRemoveGroups = [];
      this.customGroupsList = [];
      this.isLoading = false;
      this.isUpdating = false;
      this.$emit('close');
    },
    async fetchCustomGroupList() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const response = await groupsService.listGroups(programId);
        const {data} = response;
        const {items} = data;
        if(items.length > 0) {
          this.customGroupsList = items.filter(i => {
            if(i.type_id === groupType.CUSTOM) {
              return {
                id: i.id,
                name: i.name
              };
            }
          });
        } 
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Custom Groups Not loaded!'));
      } finally {
        this.isLoading = false;
      }
    },
    async createNewGroup() {
      try {
        const programId = this.$route.params.id;
        this.isUpdating = true;
        const data = {
          name: this.customGroupName,
          users: this.users.map(user => ({id: user.id })),
          groups: []
        };
        await groupsService.createCustomGroup(programId, data);
        this.$toast(makeSuccessToast('Custom Group Created and Users added successfully.'));
        this.fetchCustomGroupList();
        this.customGroupName = '';
        this.$emit('close');
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e.response.data.message));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;    
      }
    },
    showModal() {
      this.shallShowFormGroupModal = true;
    },
    async addUsersToGroups () {
      try {
        const programId = this.$route.params.id;
        this.isUpdating = true;
        const data = {
          users: this.users.map(user => ({id: user.id })),
          groups: this.selectedAddGroups.map(g => ({id: g})),
        };
        await groupsService.patchAddUsersGroups(programId, data);
        this.$toast(makeSuccessToast('Users Added to Groups successfully.'));
        this.$emit('close');
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e.response.data.message));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;    
      }
    },
    async removeUsersToGroups () {
      try {
        const programId = this.$route.params.id;
        this.isUpdating = true;
        const data = {
          users: this.users.map(user => ({id: user.id })),
          groups: this.selectedRemoveGroups.map(g => ({id: g})),
        };
        await groupsService.patchRemoveUsersGroups(programId, data);
        this.$toast(makeSuccessToast('Users Remove From Groups successfully.'));
        this.$emit('close');
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e.response.data.message));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;    
      }
    }
  }
};
</script>
       