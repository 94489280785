<template>
  <b-modal
    id="suspend-user-modal"
    ref="suspendUserModal"
    title="Suspend Participant"
    v-model="showModal"
    centered
    @ok="suspendUser"
  >
    <p>
     {{ modalMessage }}
    </p>
    <b-form>
       <validation-provider
                v-slot="validationContext"
                ref="note"
                :rules="{ required: true }"
                name="Note"
              >
      <b-row>
        <b-col sm="12">
          <b-form-group 
           :label="$t('Reason for suspension')"
            label-for="reason-for-suspension"
          
          >
            <b-form-textarea
              id="reason-for-suspension"
              class="mt-1"
              v-model="note"
              placeholder="Enter new note here ..."
              maxlength="2000"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
             {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      </validation-provider>
    </b-form>
    <template #modal-footer="{ ok, cancel}">
      <b-button
        size="md"
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        size="md"
        variant="primary"
        :disabled="note.length === 0"
        @click="ok"
      >
        <b-spinner
          v-if="isSubmitting"
          small
        />
        <span
          v-if="isSubmitting"
        > Updating...</span>
        <span v-else>Suspend</span>
      </b-button>
    </template>
    <!-- eslint-disable vue/no-v-html -->
  </b-modal>
</template>
<script>
  
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import {
  BDropdownItem,
  BModal,
  BButton,
  BFormGroup,
  BFormTextarea,
  BSpinner,
  BCol,
  BRow,
  BForm,
BFormInvalidFeedback
} from "bootstrap-vue";
  
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import usersService  from "@/services/usersService";
import { userStatus } from "@models";
import { getValidationState } from "@/libs/utils";
import { ValidationProvider } from "vee-validate";
  
export default {
  name: 'BulkSuspendUsers',
  components: {
    BDropdownItem,
    BModal,
    BFormGroup,
    BFormTextarea,
    BButton,
    BSpinner,
    BCol,
    BRow,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ActionDropdownItem
  },
  props:{
    users: {
      type: Array,
      required: true
    },
    isClientUser: {
      type:Boolean
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      note: '',
      isSubmitting: false
    };
  },
  computed: {
    showModal () {
      return this.show;
    },
    modalTitle () {
      return  'Suspend Participant';
    },
    modalMessage () {
      return 'Are you sure you want to suspend this users? They will not have access to this program whilst they are suspended. Please note that this is only suspending the participant from this program. If they need to be suspended from all programs, please contact Art of Mentoring.'
    },
    updatedStatus () {
      return userStatus.SUSPENDED; 
    },
    successMsg () {
      return  'Participant suspended.';
    }
  },
  methods: {
    async suspendUser() {
      this.isSubmitting = true;
      try {
        let result = null;
        if (this.isClientUser) {
          const clientId = this.$route.params.id || this.$route.params.clientId;
          result = await usersService.setClientParticipantStatus(clientId, this.user.id, {status_id: this.updatedStatus});
        } else {
          const data = {
            status_id: this.updatedStatus,
            note:  this.note ? this.note : null,
            users: this.users.map(user => ({ id: user.id }))
          };
          const programId = this.$route.params.id;
          result = await usersService.setManyParticipantStatus(programId, data);
        }
        this.isSubmitting = false;
        this.$emit('close');
        if (result) {
          this.$toast(makeSuccessToast(`${this.successMsg}`));
        }
      } catch (e) {
        console.log(e);
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      }
    }
  },
  setup() {
    return {
      getValidationState,
    };
  },
};
</script>
   