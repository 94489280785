<template>
  <b-modal 
    ref="delete-confirmation-modal" 
    :title="title"
    :ok-disabled="okDisabled"
    :ok-title="okTitle"
    :cancel-title="cancelTitle"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    size="sm"
    :hide-header-close="false"
    :centered="true"
    @close="onCloseClick"
    @cancel="onCancelClick"
    @ok="onOkClick"
  >
    <div>
      <p>{{ message }}</p>
      <p>
        Please type <em>DELETE</em> to confirm. 
      </p>
      <b-form-input 
        v-model="inputText" 
        placeholder="DELETE"
        @input="onInput"
        @keyup.enter="onEnter"
      />
    </div>
  </b-modal>
</template>

<script>
import { BFormInput } from "bootstrap-vue";
export default {
  name: "AomDeleteConfirmationModal",
  components: {
    BFormInput
  },
  data() { 
    return {
      title: 'Confirm Delete',
      message: '',
      okTitle: 'OK',
      cancelTitle: 'Cancel',
      okDisabled: true,
      resolveReject: null,
      inputText: ''
    };
  },
  methods: {
    onInput(val) {
      this.okDisabled = val.trim().toUpperCase() !== 'DELETE';
    },
    confirmDelete(message, options) {
      this.okDisabled = true;
      this.inputText = '';
      this.message = message;
      this.title = options?.title || 'Confirm Delete';
      this.okTitle = options?.okTitle || 'OK';
      this.cancelTitle = options?.cancelTitle || 'Cancel';
      this.$refs['delete-confirmation-modal'].show();
      return new Promise((resolve, reject) => {
        this.resolveReject = { resolve, reject };
      });
    },
    onEnter() {
      if (this.inputText.toUpperCase() !== 'DELETE') {
        return;
      }

      this.$refs['delete-confirmation-modal'].hide();
      this.onOkClick();
    },
    onOkClick() {
      if (typeof this.resolveReject?.resolve === 'function') {
        this.resolveReject?.resolve(true);
      }
    },
    onCancelClick() {
      if (typeof this.resolveReject?.resolve === 'function') {
        this.resolveReject?.resolve(false);
      }
    },
    onCloseClick() {
      if (typeof this.resolveReject?.resolve === 'function') {
        this.resolveReject?.resolve(false);
      }
    }
  }
};
</script>
