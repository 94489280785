<template>
  <action-dropdown-item
    link-class="d-flex align-items-center"
    @click.prevent="resendAccess"
  >
    <feather-icon
      size="16"
      icon="MailIcon"
      class="mr-50"
    />
    <span>Resend Access</span>
  </action-dropdown-item>
</template>
<script>
      
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import {  
  BDropdownItem
} from "bootstrap-vue";
      
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import usersService  from "@/services/usersService";  
      
export default {
  name: 'ResendAccess',
  components: {
    ActionDropdownItem,
    BDropdownItem
  },
  props:{
    user: {
      type: Object,
      default: () => {},
      required: true
    },
    userId: {
      type:Number,
      required: true
    }
  },
   
  methods: {
    resendAccess() {
      this.$bvModal
        .msgBoxConfirm(`Do you want to resend a link to this user’s email? This will allow them to reset their password. Nothing will happen if the link isn’t used.`, {
          title: `Resend Access`,
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Resend Link',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              const programId = this.$route.params.id;
              const data = {
                users: [{id: this.userId}]
              };
              const result = await usersService.sendManyParticipantPasswordReset(programId, data);
              if (result) {
                this.$toast(makeSuccessToast('Password reset link has been sent to participants registered email!.'));
                this.loadItems();
              }
            } catch (e) {
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            }
          }
        });
    },
  }
};
</script>
       